<template>
  <div>

    <NavBar />
    <router-view />
    
    
  
  </div>
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'
import NavBar from './components/NavBar.vue';

export default {
  name: 'App',
  components: {
    NavBar
  }
}
</script>

<style>
@import 'bootstrap/dist/css/bootstrap.min.css';


</style>
