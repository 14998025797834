import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/HomePage.vue';
import About from '../views/AboutPage.vue';
import TestSystems from '../views/TestSystems.vue';
import IntStandards from '../views/IntStandards.vue';
import AdasSystems from '../views/AdasSystems.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/systems',
    name: 'AdasSystems',
    component: AdasSystems
  },
  { path: '/tests', component: TestSystems, name: 'TestSystems' },
  { path: '/standards', component: IntStandards, name: 'Standards' }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;