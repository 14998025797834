<template>
    <div>
      <h1>About Page</h1>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AboutPage'
  };
  </script>