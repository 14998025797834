<template>
  <div class="container">
    <b-navbar toggleable="lg" type="dark" variant="primary">
      <b-navbar-brand href="#">ADAS Test Systems</b-navbar-brand>
    </b-navbar>
    
    <b-container fluid class="mt-4">
      <div class="scroll-container">
        <div class="card-container">
          <div class="system-card">
            <section id="hil-test" class="p-3 border rounded">
              <h2><b-icon icon="server" class="mr-2"></b-icon> Hardware-in-the-Loop (HiL) Test</h2>
              <p>HiL testing simulates the behavior of an ADAS system in a virtual environment by integrating the actual hardware components with simulated inputs.</p>
            </section>
          </div>
          <div class="system-card">
            <section id="sil-test" class="p-3 border rounded">
              <h2><b-icon icon="cogs" class="mr-2"></b-icon> Software-in-the-Loop (SiL) Test</h2>
              <p>SiL testing evaluates the functionality of ADAS software algorithms by running them in a simulated environment without hardware integration.</p>
            </section>
          </div>
          <div class="system-card">
            <section id="real-road-test" class="p-3 border rounded">
              <h2><b-icon icon="road" class="mr-2"></b-icon> Real Road Testing</h2>
              <p>Real road testing involves assessing ADAS performance in real-world driving conditions to validate system behavior and reliability.</p>
            </section>
          </div>
          <div class="system-card">
            <section id="electronic-equipment-test" class="p-3 border rounded">
              <h2><b-icon icon="microchip" class="mr-2"></b-icon> Electronic Equipment Testing</h2>
              <p>Electronic equipment testing ensures the functionality and compatibility of individual ADAS components such as sensors and controllers.</p>
            </section>
          </div>
          <!-- Add more test systems as needed -->
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'AdasTestSystemsPage',
};
</script>

<style scoped>
@import 'bootstrap/dist/css/bootstrap.min.css';

.scroll-container {
  overflow-y: scroll;
  max-height: 85vh;
  display: flex;
  justify-content: center;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  border-radius: 10px;
  scrollbar-width: none; /* Firefox */
}

.scroll-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.system-card {
  width: 360px;
  height: 360px;
  margin: 0 10px 20px 10px;
  display: flex;
  flex-direction: column;
  background: linear-gradient(to bottom right, #0d0d6a, navy);
  color: #fff;
  border-radius: 10px;
  overflow-y: auto;
}

.system-card section {
  flex-grow: 1;
  width: 100%;
}

h2 {
  color: inherit;
}

section {
  background-color: rgba(255, 255, 255, 0.1);
}

b-footer {
  position: fixed;
  width: 100%;
  bottom: 0;
}

@media (max-width: 767px) {
  .system-card {
    width: calc(100% - 20px);
    margin: 0 10px 20px 10px;
    height: auto;
  }
}
</style>
