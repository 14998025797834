<template>
    <div class="container">
      <b-navbar toggleable="lg" type="dark" variant="primary">
        <b-navbar-brand href="#">ADAS Systems</b-navbar-brand>
      </b-navbar>
      
      <b-container fluid class="mt-4">
        <div class="scroll-container">
          <div class="card-container">
            <div class="system-card">
              <section id="adaptive-cruise-control" class="p-3 border rounded">
                <h2><b-icon icon="speedometer2" class="mr-2"></b-icon> Adaptive Cruise Control</h2>
                <p>Adaptive Cruise Control (ACC) automatically adjusts the vehicle speed to maintain a safe distance from vehicles ahead.</p>
              </section>
            </div>
            <div class="system-card">
              <section id="lane-departure-warning" class="p-3 border rounded">
                <h2><b-icon icon="arrow-left-right" class="mr-2"></b-icon> Lane Departure Warning</h2>
                <p>Lane Departure Warning (LDW) alerts the driver when the vehicle begins to move out of its lane unless a turn signal is on.</p>
              </section>
            </div>
            <div class="system-card">
              <section id="blind-spot-detection" class="p-3 border rounded">
                <h2><b-icon icon="eye-slash" class="mr-2"></b-icon> Blind Spot Detection</h2>
                <p>Blind Spot Detection (BSD) monitors the side of the vehicle to alert the driver of vehicles in the blind spot.</p>
              </section>
            </div>
            <div class="system-card">
              <section id="automatic-emergency-braking" class="p-3 border rounded">
                <h2><b-icon icon="exclamation-circle" class="mr-2"></b-icon> Automatic Emergency Braking</h2>
                <p>Automatic Emergency Braking (AEB) detects an imminent collision and automatically applies the brakes to avoid or mitigate the impact.</p>
              </section>
            </div>
            <div class="system-card">
              <section id="parking-assist" class="p-3 border rounded">
                <h2><b-icon icon="parking" class="mr-2"></b-icon> Parking Assist</h2>
                <p>Parking Assist helps drivers park by providing guidance and, in some systems, by taking control of the steering.</p>
              </section>
            </div>
            <!-- Add more system cards as needed -->
          </div>
        </div>
      </b-container>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AdasSystemsPage',
  };
  </script>
  
  <style scoped>
  @import 'bootstrap/dist/css/bootstrap.min.css';
  
  .scroll-container {
    overflow-y: scroll;
    max-height: 85vh;
    display: flex;
    justify-content: center;
  }
  
  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
    border-radius: 10px;
    scrollbar-width: none; /* Firefox */
  }
  
  .scroll-container::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  
  .system-card {
    width: 360px;
    height: 360px;
    margin: 0 10px 20px 10px;
    display: flex;
    flex-direction: column;
    background: linear-gradient(to bottom right, #0d0d6a, navy);
    color: #fff;
    border-radius: 10px;
    overflow-y: auto;
  }
  
  .system-card section {
    flex-grow: 1;
    width: 100%;
  }
  
  h2 {
    color: inherit;
  }
  
  section {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  b-footer {
    position: fixed;
    width: 100%;
    bottom: 0;
  }
  
  @media (max-width: 767px) {
    .system-card {
      width: calc(100% - 20px);
      margin: 0 10px 20px 10px;
      height: auto;
    }
  }
  </style>
  