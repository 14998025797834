<template>
    <div class="container">
      <b-navbar toggleable="lg" type="dark" variant="primary">
        <b-navbar-brand href="#">ADAS International Organizations</b-navbar-brand>
      </b-navbar>
      
      <b-container fluid class="mt-4">
        <div class="scroll-container">
          <div class="card-container">
            <div class="organization-card">
              <section id="iso" class="p-3 border rounded">
                <h2><b-icon icon="globe" class="mr-2"></b-icon> International Organization for Standardization (ISO)</h2>
                <p>The ISO develops and publishes international standards related to ADAS technologies.</p>
              </section>
            </div>
            <div class="organization-card">
              <section id="sae" class="p-3 border rounded">
                <h2><b-icon icon="cogs" class="mr-2"></b-icon> Society of Automotive Engineers (SAE)</h2>
                <p>The SAE establishes technical standards for ADAS systems to ensure interoperability and safety.</p>
              </section>
            </div>
            <div class="organization-card">
              <section id="eu" class="p-3 border rounded">
                <h2><b-icon icon="flag-eu" class="mr-2"></b-icon> European Union (EU)</h2>
                <p>The EU implements regulations and directives concerning ADAS technologies to enhance road safety and environmental protection.</p>
              </section>
            </div>
            <div class="organization-card">
              <section id="ieee" class="p-3 border rounded">
                <h2><b-icon icon="microchip" class="mr-2"></b-icon> Institute of Electrical and Electronics Engineers (IEEE)</h2>
                <p>The IEEE develops standards and guidelines for various aspects of ADAS technology, including sensors and communication protocols.</p>
              </section>
            </div>
            <div class="organization-card">
              <section id="itu" class="p-3 border rounded">
                <h2><b-icon icon="globe" class="mr-2"></b-icon> International Telecommunication Union (ITU)</h2>
                <p>The ITU works on standards related to communication technologies used in ADAS systems, such as vehicle-to-vehicle (V2V) and vehicle-to-infrastructure (V2I) communication.</p>
              </section>
            </div>
            <div class="organization-card">
              <section id="euroncap" class="p-3 border rounded">
                <h2><b-icon icon="shield-alt" class="mr-2"></b-icon> Euro NCAP</h2>
                <p>Euro NCAP (European New Car Assessment Programme) conducts safety tests and provides ratings for ADAS-equipped vehicles to promote safer cars in Europe.</p>
              </section>
            </div>
            <!-- Add more organizations as needed -->
          </div>
        </div>
      </b-container>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AdasInternationalOrganizationsPage',
  };
  </script>
  
  <style scoped>
  @import 'bootstrap/dist/css/bootstrap.min.css';
  
  .scroll-container {
    overflow-y: scroll;
    max-height: 85vh;
    display: flex;
    justify-content: center;
  }
  
  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
    border-radius: 10px;
    scrollbar-width: none; /* Firefox */
  }
  
  .scroll-container::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  
  .organization-card {
    width: 360px;
    height: 360px;
    margin: 0 10px 20px 10px;
    display: flex;
    flex-direction: column;
    background: linear-gradient(to bottom right, #0d0d6a, navy);
    color: #fff;
    border-radius: 10px;
    overflow-y: auto;
  }
  
  .organization-card section {
    flex-grow: 1;
    width: 100%;
  }
  
  h2 {
    color: inherit;
  }
  
  section {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  b-footer {
    position: fixed;
    width: 100%;
    bottom: 0;
  }
  
  @media (max-width: 767px) {
    .organization-card {
      width: calc(100% - 20px);
      margin: 0 10px 20px 10px;
      height: auto;
    }
  }
  </style>
  