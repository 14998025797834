<template>
  <div>
    <div class="fixed-flex">
      <div class="type-box">
        <div class="typing-container">
        <div class="typing-animation">Hi,ADAS Engineer</div>
        
    </div>
    
      </div>
      
    
    </div>
   
    
    <div class="universe-container">
      <div class="space universe"></div>
    <div class="space stars1"></div>
    <div class="space stars2"></div>
    <div class="space stars3"></div>
    <div class="space stars4"></div>
    <div class="space stars5"></div>
    <div class="space stars6"></div>
    </div>
    
  <!-- <div class="logos-container">
    <div class="logos">
<div class="logo_items">
  <div class="sliding-div"></div>
  <div class="sliding-div"></div>
  <div class="sliding-div"></div>
  <div class="sliding-div"></div>
  
</div>
<div class="logo_items">
  <div class="sliding-div"></div>
  <div class="sliding-div"></div>
  <div class="sliding-div"></div>
  <div class="sliding-div"></div>
</div>

  
</div>
  </div> -->
  <div class="image-div">
  <div class="image-container">
        <img src="../assets/adas.png" alt="Responsive Image">
    </div>
  </div>
  
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  data() {
    return {
      boxes: [
        { id: 1, title: 'Systems', content: '' },
        { id: 2, title: 'Test', content: '' },
        { id: 3, title: 'Standards', content: '' },

      ]
    };
  }
};
</script>

<style>
@import 'bootstrap/dist/css/bootstrap.min.css';
.image-div {
  margin: 0;
    padding: 0;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.image-container img {
   
    max-height: 100%;
    height: auto;
    width: auto;
}
.universe-container {
  overflow: hidden; position: absolute;display: flex;align-items: center;justify-content: center;vertical-align: middle;height: 100vh;width: 100vw;
}
.logos-container {
  position: absolute;width: 100vw;height:20%;display: flex;bottom: 40vh;justify-content: center;align-items: center;vertical-align: middle;
}
.fixed-flex{
  position: fixed;display: flex;width: 100vw;height: 100vh;
}
.type-box{
  margin:30px;  background-color: black;color: white;width: 300px;height: 100px;border: 3px solid rgba(55, 0, 255, 0.185);border-radius: 12px;position: absolute;
}
.icon-div{
  width:96px;
  height:96px;
  border:1px solid greenyellow;
}

/* Typing animation styles */
.typing-container {
  margin-left: 11px;
    margin-top: 30px;
    font-family: 'Courier New', Courier, monospace;
    font-size: 24px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden; /* Ensures the text doesn't wrap to a new line */
     /* Creates the cursor effect */
    width: 100px; /* Set width to fit the text length */
    animation: typing 3s steps(16);
    animation-fill-mode: forwards; /* Ensures the text stays visible after the animation */
    padding-left: 12px;
    padding-top: 0px;
}
.typing-container-2 {
  margin-left: 11px;
    margin-top: 36px;
    font-family: 'Courier New', Courier, monospace;
    font-size: 24px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden; /* Ensures the text doesn't wrap to a new line */
     /* Creates the cursor effect */
    width: 100px; /* Set width to fit the text length */
    animation: typing 3s steps(18);
    animation-fill-mode: forwards; /* Ensures the text stays visible after the animation */
    padding-left: 12px;
    padding-top: 0px;
}
/* Keyframes for typing effect */
@keyframes typing {
    from { width: 0; }
    to { width: 18ch; } /* Adjust '13ch' to match the length of the text */
}
@keyframes blink {
    from, to { border-color: transparent; }
    50% { border-color: rgb(255, 255, 255); }
}
/* Keyframes for cursor blink effect */

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: hidden;
}

body {
  background: radial-gradient(circle at bottom, navy 0, black 100%);
  height: 100vh;
  overflow: hidden;
  margin: 0px;
}
.space {
  background: rgba(38, 0, 128, 0.1) center / 200px 200px round;
  height: 400vw;
  width: 400vw;
  border-radius: 50vw;
  position: absolute;
  overflow: hidden;
 
  
  
}
.universe {
  animation: space 10s  infinite;
  border: 1px dashed purple;
  background-image:
    radial-gradient(
      1px 1px at 25px 5px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      1px 1px at 50px 25px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      1px 1px at 125px 20px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      1.5px 1.5px at 50px 75px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      2px 2px at 15px 125px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      2.5px 2.5px at 110px 80px, 
      white, 
      rgba(255, 255, 255, 0)
    );
}
.stars1 {
  rotate: 30deg;
  animation: space 37s  infinite;
  background-image:
    radial-gradient(
      1px 1px at 25px 5px, 
      white, 
      rgba(43, 12, 12, 0)
    ),
    radial-gradient(
      1px 1px at 50px 25px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      1px 1px at 125px 20px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      1.5px 1.5px at 50px 75px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      2px 2px at 15px 125px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      2.5px 2.5px at 110px 80px, 
      white, 
      rgba(255, 255, 255, 0)
    );
}
.stars2 {
  rotate: 45deg;
  animation: space 24s  infinite;
  background-image:
    radial-gradient(
      1px 1px at 25px 5px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      1px 1px at 50px 25px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      1px 1px at 125px 20px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      1.5px 1.5px at 50px 75px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      2px 2px at 15px 125px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      2.5px 2.5px at 110px 80px, 
      white, 
      rgba(255, 255, 255, 0)
    );
}
.stars3 {
  rotate: 180deg;
  animation: space 28s  infinite;
  background-image:
    radial-gradient(
      1px 1px at 25px 5px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      1px 1px at 50px 25px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      1px 1px at 125px 20px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      1.5px 1.5px at 50px 75px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      2px 2px at 15px 125px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      2.5px 2.5px at 110px 80px, 
      white, 
      rgba(255, 255, 255, 0)
    );
}
.stars4 {
  rotate: 90deg;
  animation: space 120s  infinite;
  background-image:
    radial-gradient(
      1px 1px at 25px 5px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      1px 1px at 50px 25px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      1px 1px at 125px 20px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      1.5px 1.5px at 50px 75px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      2px 2px at 15px 125px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      2.5px 2.5px at 110px 80px, 
      white, 
      rgba(255, 255, 255, 0)
    );
}
.stars5 {
  rotate: 145deg;
  animation: space 44s  infinite;
  background-image:
    radial-gradient(
      1px 1px at 25px 5px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      1px 1px at 50px 25px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      1px 1px at 125px 20px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      1.5px 1.5px at 50px 75px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      2px 2px at 15px 125px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      2.5px 2.5px at 110px 80px, 
      white, 
      rgba(255, 255, 255, 0)
    );
}
.stars6 {
  animation: space 62s  infinite;
  background-image:
    radial-gradient(
      1px 1px at 25px 5px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      1px 1px at 50px 25px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      1px 1px at 125px 20px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      1.5px 1.5px at 50px 75px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      2px 2px at 15px 125px, 
      white, 
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      2.5px 2.5px at 110px 80px, 
      white, 
      rgba(255, 255, 255, 0)
    );
}


@keyframes space {
  40% {
    opacity: 0.75;
  }
  50% {
    opacity: 0.45;
  }
  60% {
    opacity: 0.75;
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes slides {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.logos {
  overflow: hidden;
  padding: 15px 0px;
  white-space: nowrap;
  position: relative;
  width: 50vw;
}

.logos:before, .logos:after {
  position: absolute;
  top: 0;
  content: '';
  width: 25vw;
  height: 100%;
  z-index: 2;
}

.logos:before {
  
  left: 0;
    background: linear-gradient(to left, rgb(255 255 255 / 14%), rgb(0 0 0));
}

.logos:after {
  right: 0;
    background: linear-gradient(to right, rgb(255 255 255 / 14%), rgb(0 0 0));
}

.logo_items {
  display: inline-flex;
  animation: 30s slides infinite linear;
}

.logos:hover .logo_items {
  animation-play-state: paused;
}


.sliding-div{
  margin: 24px;
  width:25vw;height: 100px;border: 3px solid rgb(255, 255, 255);
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
}

</style>